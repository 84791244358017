// Vendors
import React, { useMemo, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import ReactGA from 'react-ga';

// Components
import PageTitle from "../components/pagetitle";
import Project from "../features/project/nftdetails";
import NftMetadata from "../components/common/NftMetadata";
import PopupModal from "../components/popupmodal";
import { MintButton } from "../components/mintButton";

// Controllers
import { useNftDetails } from "../controllers";

// utils
import dataProject from "../assets/fake-data/dataMarketplace";
import { EthSale721 } from "../contracts/abi/EthSale721";
import merkleProof from "../utils/merkleProof";

// wagmi
import {
  usePrepareContractWrite,
  useContractWrite,
  useContractRead,
  useWaitForTransaction,
  useAccount,
} from "wagmi";
import { ethers } from "ethers";
import { useCallback } from "react";

const divStyle = {
  margin: "50px 0",
};

const imgStyle = {
  marginBottom: "40px",
};

const titleStyle = {
  padding: "50px 0 59px",
};

function NftItemDetails(props) {
  const { slug } = useParams();
  const { findNFT } = useNftDetails();
  const [modalVisible, setModalVisible] = useState(false);
  const [preparedErrorMessage, setPreparedError] = useState(undefined);
  const [didMintMax, setMaxAmountMinted] = useState(false);
  const [price, setPrice] = useState("0.0");
  const [isAllowListRequired, setAllowListRequired] = useState(false);
  const [remaining, setRemaining] = useState(0);
  // Bring data from the schemas
  const NFT = useMemo(() => {
    const data = findNFT(slug);
    if (!data) {
      window.location = "/";
    }

    return data;
  }, [slug, findNFT]);

  // Mint NFT
  const { address } = useAccount();
  const args = {
    address: NFT.contractAddress,
    abi: EthSale721,
    chainId: NFT.chainId,
    functionName: isAllowListRequired ? "allowlistMint" : "publicMint",
    args: isAllowListRequired ? [merkleProof(address)] : [],
    overrides: isAllowListRequired
    ? {
        value: ethers?.utils?.parseEther(price),
      }
    : {
        from: address,
        value: ethers?.utils?.parseEther(price),
      },
  };
  const {
    error: prepareError,
    isError: isPrepareError,
    isLoading: isLoadingPrepare,
    isSuccess: isSuccessPrepare,
  } = usePrepareContractWrite(args);
  // Since we are setting gas manually, use these for error handling

  const {
    config,
    isError: isPrepareError2,
    isLoading: isLoadingPrepare2,
    isSuccess: isSuccessPrepare2,
  } = usePrepareContractWrite({
    ...args,
    overrides: {
      ...args.overrides,
      gasLimit: 450000,
    },
    
  });
  const { data, error, isError, write } = useContractWrite(config);

  const { data: maxAmountMinted } = useContractRead({
    address: NFT.contractAddress,
    abi: EthSale721,
    functionName: "maxAmountMinted",
    chainId: NFT.chainId,
  });

  const { data: stats, refetch } = useContractRead({
    address: NFT.contractAddress,
    abi: EthSale721,
    functionName: "allStats",
    chainId: NFT.chainId,
  });

  const { data: allowListRequired } = useContractRead({
    address: NFT.contractAddress,
    abi: EthSale721,
    functionName: "allowListRequired",
    chainId: NFT.chainId,
  });

  const { isLoading, isSuccess, isError: transError } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess() {
      refetch?.();
      setMaxAmountMinted(
        maxAmountMinted.toString() === "0" ? null : "already minted max"
      );
    },
  });

  useEffect(() => {
    if (NFT.soldOut) {
      return;
    }
    try {
      const price = ethers.utils.formatEther(stats[0]);
      const remaining = stats[1]?.toString();
      setPrice(price.toString());
      setAllowListRequired(allowListRequired.toString() === "0" ? false : true);
      setPreparedError(
        prepareError?.reason?.replace("execution reverted: ", "")
      );
      setRemaining(remaining);
    } catch (err) {
      console.log(err);
    }
  }, [
    stats,
    address,
    allowListRequired,
    isAllowListRequired,
    prepareError,
    NFT.soldOut,
  ]);

  const buy = useCallback(async () => {
    setModalVisible(true);
    await write?.();
  }, [write]);

  const isLoadingAll =
    isLoadingPrepare ||
    (!isSuccessPrepare && !isPrepareError) ||
    isLoadingPrepare2 ||
    (!isSuccessPrepare2 && !isPrepareError2);

  const handleModalClose = () => {
    refetch?.();
    setModalVisible(false)
  }
  return (
    <div className="page-item-details">
      <PageTitle style={titleStyle} />
      <section className="tf-section tf-item-details pb-mobie">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12" style={imgStyle}>
              <div className="image-details">
                <video
                  className="video product-banner-video"
                  poster={NFT.image}
                  alt={NFT.title}
                  data-product-single-media
                  playsinline
                  loop
                  preload
                  controls
                >
                  {NFT.animation_url ? (
                    <source id="mp4" src={NFT.animation_url} type="video/mp4" />
                  ) : null}
                </video>
              </div>

              <div className="item-details">
                <div className="list-product">
                  <NftMetadata metadata={NFT.properties} />
                </div>
                <div>
                  <div style={divStyle}>{ReactHtmlParser(NFT.leftText)}</div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="item-details">
                <h5>
                  <i>{NFT.title}</i> - AR
                </h5>
                <h5>{NFT.subHeader}</h5>
                {!NFT.soldOut && (
                  <div>
                    Price:{" "}
                    {NFT.isPresale
                      ? `${NFT.price} ETH`
                      : isLoadingAll
                      ? "-"
                      : price.toString() === "0.0"
                      ? "Free"
                      : `${price} ETH`}
                  </div>
                )}
                {!NFT.isPresale && (
                  <div>
                    Remaining:{" "}
                    {isLoadingAll
                      ? "-"
                      : remaining === 0
                      ? "Sold Out"
                      : remaining}
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: 20,
                  }}
                >
                  {NFT.isPresale ? (
                    <button
                      className="tf-button btn-effect"
                      type="button"
                      disabled
                      style={{ marginRight: "20px" }}
                    >
                      <span className="boder-fade"></span>
                      <span className="effect">DROPS ON: {NFT.dropDate}</span>
                    </button>
                  ) : remaining === "0" || NFT.soldOut ? (
                    <button
                      className="tf-button btn-effect btn-alt"
                      style={{ marginRight: "20px" }}
                      onClick={() =>
                        window.open(
                          `https://opensea.io/collection/${NFT.openseaUrl}`,
                          "_blank"
                        )
                      }
                      type="button"
                    >
                      <span className="boder-fade"></span>
                      <span className="effect">Sold Out. View on OpenSea</span>
                    </button>
                  ) : (
                    <div style={{ marginRight: "20px" }}>
                      <MintButton
                        handleMint={buy}
                        canMint={!address || !!isSuccessPrepare && !isLoadingAll}
                        disabledMessage={
                          isLoadingAll
                            ? "Loading ..."
                            : preparedErrorMessage || didMintMax
                        }
                        requiredChain={NFT.chainId}
                      />
                    </div>
                  )}
                  <button
                    className="tf-button btn-effect"
                    onClick={() =>
                      window.open(`https://www.immuse.xyz/stay-updated`)
                    }
                    type="button"
                  >
                    <span className="boder-fade"></span>
                    <span className="effect">Stay Updated</span>
                  </button>
                </div>
                <span>{ReactHtmlParser(NFT.description)}</span>
              </div>
            </div>
          </div>
          {NFT.ugc && (
            <div style={{ marginTop: 50 }}>
              <video
                className="video product-banner-video"
                alt={NFT.title}
                data-product-single-media
                playsinline
                loop
                preload
                controls
                src={NFT.ugc}
                type="video/mp4"
              />
            </div>
          )}
        </div>
      </section>
      {modalVisible && (
        <PopupModal
          handleClose={() => handleModalClose()}
          result={isSuccess}
          address={address}
          loading={isLoading}
          hash={data?.hash}
          error={isPrepareError || isError || transError}
          message={(prepareError || error)?.message}
        />
      )}
      <Project data={dataProject} />
    </div>
  );
}

export default NftItemDetails;
