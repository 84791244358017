const dataRoadmap = [
    {
        id: 1,
        time: 'Q3 2022',
        list: [
            {
                text: 'Launch beta app & marketplace'
            },
            {
                text: 'Initial drop of IMMUSE NFTs'
            },
            {
                text: 'Collabs with other Creators and Communities'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Q4 2022',
        list: [
            {
                text: 'Full remix & minting functionality'
            },
            {
                text: 'Tokenomics launched'
            },
            {
                text: ' Art Basel Launch party'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Q1 2023',
        list: [
            {
                text: 'DAO setup'
            },
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Q2 2023',
        list: [
            {
                text: 'DAO launched'
            },
            {
                text: 'Creator grants launched'
            },
            {
                text: 'Record label integrations'
            },
        ],
        positon: 'right'
    },
]

export default dataRoadmap;