const menus = [
    // {
    //     id: 1,
    //     name: 'Team Details',
    //     links: '/team-details',
    // },
    // {
    //     id: 2,
    //     name: 'FAQ',
    //     links: '/faq',
    // },
]

export default menus;