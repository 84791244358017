import React, { useState } from "react";
import PropTypes from "prop-types";
import ProjectItem from "../project-item";
import "../../../scss/components/form.scss";
import Button from "../../../components/button";

import Upcomingdrops from "../../../features/upcomingdrops";
import { useMemo } from "react";

Marketplace.propTypes = {
  data: PropTypes.array,
};

function Marketplace(props) {
  const { data } = props;
  const { current, upcoming } = useMemo(() => {
    return {
      current: data?.filter((e) => !e.isPresale),
      upcoming: data?.filter((e) => e.isPresale),
    };
  }, []);
  const [visible, setVisible] = useState(16);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  return (
    <section className="tf-section tf-project home-2 nft-item">
      {!!current?.length && (
        <div className="container">
          <h4 className="title">AVAILABLE NOW</h4>
          <div className="row">
            {current.slice(0, visible).map((item, i) => {
              return <ProjectItem item={item} key={i} />;
            })}
            {visible < current.length && (
              <div className="col-md-12">
                <div
                  className="btn-about center m-t16"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="800"
                >
                  <Button title="Load More" path="#" onClick={showMoreItems} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/*<div className="upcoming">*/}
      {/*  <Upcomingdrops data={upcoming} />*/}
      {/*</div>*/}
    </section>
  );
}

export default Marketplace;
