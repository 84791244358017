import bgImg1 from '../images/background/AR_OldMan.jpeg';
import img1 from '../images/common/DrStrangerThings1024_FrameNo.0000_Thumbnail002.jpg';

const dataSlider = [
    {
        id: 1,
        title: 'The Future of visual music',
        desc1: 'UPCOMING DROP:',
        desc2: 'DRSTRANGERTHINGS BY IMMUSE',
        desc3: 'SEP 30 11AM PT .025 ETH',
        bgImg: bgImg1,
        img : img1
    },
]

export default dataSlider;