// Methods and Values
export const POLYGON_NETWORK_ID = "137";
export const ETHEREUM_NETWORK_ID = "1";
export const ETHEREUM_REQUEST_METHOD = "eth_requestAccounts";

// API's
export const GOERLI_ALCHEMY_API =
  "https://eth-goerli.g.alchemy.com/v2/NVVcuMvzsbZkkoB2HPdh0_y0CtklTx_Q";
export const MUMBAI_ALCHEMY_API =
  "https://polygon-mumbai.g.alchemy.com/v2/x5KVTi6vWIzPY-jhIBtAbv-sqnUHSAAG";
export const POLYGON_ALCHEMY_API =
  "https://polygon-mainnet.g.alchemy.com/v2/bjV-Ngk6JEvF32FwcHpyQV3IIyq9Abc8";
export const IMMUSE_BACKEND_API = "https://drops.immuse.xyz/api/";
export const POLYGON_GAS_STATION_API = "https://gasstation-mainnet.matic.network/v2";

// Values
export const GAS_LIMIT = 900000; // More GasLimit the Txs are going faster
