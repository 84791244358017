import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '../styles.scss' ;
import ProjectItem from '../project-item';

Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const {data} = props;

    const [dataBlock] = useState({
        sutitle: 'NFT',
        title: 'Arts & collectibles'
    })
    return (
        <section className="tf-section tf-project nft-details pb-mobie">
        </section>
    );
}

export default Project;