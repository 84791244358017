import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./styles.scss";

ProjectItem.propTypes = {
  item: PropTypes.object,
};

function ProjectItem(props) {
  const { item } = props;
  return (
    <Link to={`/${item.slug}`} disabled={item.disabled} className="img-box">
      <img src={item.artist_image || item.image} alt="crybox" />
      <div className="content">
        <div>{item.artistName}</div>
        <i className="artist">{item.title}</i>
      </div>
    </Link>
  );
}

export default ProjectItem;
