const dataFaq = [
    {
        id: 1,
        title: 'What is an NFT?',
        content: 'An NFT is a non-fungibe token. This is best explained in reverse order: A token: anything used to represent something often in an enchange of goods or services. Fungible refers to tokens that are interchangable and valued the same as each other. Non-fungible tokens are tokens that are one of a kind and cannot be exchanged as if they are an equivalent. ie: A painting by Picasso is not interchangable with another painting by a different artist. The purchase of an NFT can be considered as a certificate of authenticity and ownership of a digital asset.',
        show: 'show'
    },
    {
        id: 2,
        title: 'How do NFTs support Artists over traditional rev models?',
        content: 'There are many ways that artists can earn revenue. NFTs represent a "direct to fan" model, where artists are able to retain the significant portion of revenues generated from the sale of their work. In addition, artists can retain a re-sale royalty giving them a small percentage of all future sales of their art on secondary markets.  This way, anytime a financial transaction occurs around their artwork, the artist is fairly compensated.',
    },
    {
        id: 3,
        title: 'What is the environmental impact of an Immuse NFT?',
        content: 'NFTs have received a lot of scrutiny because of questions about some blockchains impact on the environment.  We are strong supporters of any initiative that helps lessen climate change and environmental impact. Our NFTs are minted on Polygon, which is 99.975% more efficient that Bitcoin - meaning that one minted NFT has the same environmental impact as one person streaming 20 minutes of online video.',
    },
    {
        id: 4,
        title: 'Do I have to have Web3 knowledge to participate? / where can I learn about Web3?',
        content: 'Any prior knowledge of Web3 is helpful but we will be there every step of the way to help you through the process and give you the resources to expand your Web3 knowledge further. Please join our discord to stay up to date on Web3 innovation, news and events.',
    },
    {
        id: 5,
        title: 'What is a wallet and a wallet address?',
        content: 'A wallet is used for storing your NFTs and cryptocurrencies. Your wallet address is a series of letters and number that together act as a personal identifier of your wallet, similar to a bank account number or phone number.',
    },
    {
        id: 6,
        title: 'What is the difference between a hardware wallet and a software wallet?',
        content: 'A hardware wallet is a physical device. It is a brand new wallet so when you set it up, you get a brand new seed phrase which will be your recovery key. It can hold many different assets such as different currencies (ETH, BTC, etc...) or NFTs stored on the supported blockchains. Software wallets, also known as Hot Wallets are web wallets, mobile wallets and desktop wallets, which are usually connected to the internet, making them easier to use. Tip: NEVER SHARE YOUR SEED PHRASE WITH ANYONE, NOT EVEN "CUSTOMER SERVICE" and NEVER save it digitally or online anywhere (no - not Google docs, gmail, evernote, or anywhere else digital). Write it on paper or a metal sheet and have it in multiple locations.',
        show: 'show'
    },
    {
        id: 7,
        title: 'How do I fund my wallet to buy the NFT?',
        content: 'Using an onramp service such as Moonpay or an exchange such as Coinbase allows you to add funs quickly and easily to you preferred wallet.',
    },
    {
        id: 8,
        title: 'What are the steps to buy an IMMUSE artist NFT?',
        content: 'Visit Immuse.xyz and connect your wallet.  Our NFTs are minted on Poylgon, so you will need MATIC in your wallet.  Purchase the NFT by clicking/ on the purchase button. You will prompted to confirm this transaction, and pay any accociated gas fees by signing the smart contract. After a short moment you should see a confirmation message stating the transaction was succcessful.The NFT will then be in your custody.  By downloading the IMMUSE AR App, you will then connect your wallet, tap on Library, and you will see your IMMUSE NFT, ready to place, play and remix!',
    },
    {
        id: 9,
        title: 'Where does the NFT "work"?',
        content: 'Inside the separate Immuse mobile app, which is compatiable with Apple devices. You will be able to access the app through Apple TestFlight app.',
    },
    {
        id: 10,
        title: 'Is this a private beta testing?',
        content: 'Immuse is currently in a private Creators Beta and only available on iOS devices via TestFlight - welcome! This Creators Beta is open to both musicians and visual artists who are excited about emerging tech and willing to share their ideas for the user experience.',
    },
]

export default dataFaq;