import img1 from '../images/common/chris.png';
import img2 from '../images/common/scott.png';
import img3 from '../images/common/bc.png';
import img4 from '../images/common/brady.png';
import img5 from '../images/common/geronimo.png';
import img6 from '../images/common/aron.png';
import img7 from '../images/common/nick.png';
import img8 from '../images/common/ngozi.png';
import img9 from '../images/common/asdru.png';
import img10 from '../images/common/camilo.png';
import img11 from '../images/common/miguel.png';

const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Chris Nunes',
        position: 'Co-founder & CEO',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/slf_svrgn'
            },
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'Scott Clark',
        position: 'Co-Founder & COO',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/scottygoldmusic'
            },
        ]
    },
    {
        id: 3,
        img: img3,
        name: 'BC Biermann',
        position: 'Co-Founder & Creative Director',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/one00100one'
            },
        ]
    },
    {
        id: 4,
        img: img4,
        name: 'Brady Clark',
        position: 'Web3 A&R',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/Brady_Oh'
            },
        ]
    },
    {
        id: 5,
        img: img5,
        name: 'Geronimo Moralez IV',
        position: 'Lead Unity Dev',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/gmiv'
            },
        ]
    },
    {
        id: 6,
        img: img6,
        name: 'Aron Brown',
        position: '3D Technical Artist',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/Ateball3d'
            },
        ]
    },
    {
        id: 7,
        img: img7,
        name: 'Nick Fenn',
        position: 'Community Lead & 3D Artist A&R',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: 'https://twitter.com/voidnick1'
            },
        ]
    },
    {
        id: 8,
        img: img8,
        name: 'Ngozi Nwankwo',
        position: 'Fullstack Dev',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: ''
            },
        ]
    },
    {
        id: 9,
        img: img9,
        name: 'Asdrubal Ramirez',
        position: 'Outside Lead Unity Dev',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: ''
            },
        ]
    },
    {
        id: 10,
        img: img10,
        name: 'Camilo Hernandez',
        position: 'Outside Web3 Dev',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: ''
            },
        ]
    },
    {
        id: 11,
        img: img11,
        name: 'Miguel Orellana',
        position: 'Outside Agile Lead',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: ''
            },
        ]
    },
]

export default dataTeam;