const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Collect Visual Singles',
        text : 'Our metaverse music videos should be held in your Metamask wallet, with the MetaMask app installed on your iPhone.',
    },
    {
        id: 2,
        numb: '02',
        title: 'Install the IMMUSE Beta App',
        text : 'Install TestFlight here, then install IMMUSE here.'
    },
    {
        id: 3,
        numb: '03',
        title: "Connect MetaMask App",
        text : 'Verify your wallet holdings by signing the Chainsafe transaction, click "copy" your wallet address, and return to the Immuse app.'
    },
    {
        id: 4,
        numb: '04',
        title: "Remix Reality",
        text : 'Have fun playing with all the varied looks of the visual single and save your favorite!'
    },
]
export default dataWork;