import { chain } from "wagmi";

const aboutText = `
  <h7><b>IMMUSE VISUAL SINGLES</b></h7>
  <p>
A Visual Single is a song embodied in an AR scene that you can play, remix, record and share as your own music video, all through the Immuse Visual Music App. Visit IMMUSE.xyz to purchase Visual Singles and Download the App. Connect your wallet to view and play your Collection.
  </p>
`

export const CURRENT_NETWORK = chain.mainnet;
export const dataMarketplace = [
  {
    collectionId: 1,
    slug: "TKHeavenOnEarth",
    isPresale: false,
    collectionName: "IMMUSE",
    price: "0.05",
    contractAddress: "0x2317045A631f719B73A8Da0E4D3140f8708dCE94",
    dropDate: "Available Now",
    chainId: 1,
    leftText: aboutText,
    artistName: "TK",
    subHeader: "TK x IMMUSE",
    title: "Heaven on Earth",
    description:
        `
      Immersive, playful and psychedelic. A blissful world set in the Eternal Garden. The bottom of the scene invites discovery with its abundance of oversized mushrooms and psychedelic flora while ethereal wisps draw your eyes to a heavenly portal filling the sky above. 
      <br/>
      <br/>
      <b>ABOUT THE SONG</b><br/>
      “Heaven on Earth” is a song about the power of your imagination, originally released and sold out through Sound.xyz. It is the story of two friends who escape reality together, and build a blissful world of love and fantasy with a little help from a magic mushroom. Throughout their journey they come to find an understanding and appreciation of the very world they left behind. They realize that this world we live in is a beautiful one, and we must all do our part in preserving it, to experience a true heaven on earth.
      <br/>
      <br/>
      <b>ABOUT THE SCENE</b><br/>
      The universe of the Eternal Garden comes to life anywhere you are, directly inspired by TK’s album art, lyrics and overall vibe. A mushroom forest surrounds you with exotic foliage and butterflies aplenty. A heavenly portal rips the sky open bringing with it bursts of energy from the floor below. Remixable elements include the Mushrooms, the Ground Flora, the Butterflies, and the Heavenly Portal.
      <br/>
      <br/>
        `,
    external_url: "https://immuse.xyz",
    image: "/nfts/TK_HeavenOnEarth.jpg",
    animation_url: "/nfts/TK_HeavenOnEarth_AnimatedThumbnail.mp4",
    artist_image: "/nfts/TK.jpg",
    ugc: '/ugc/TK RMIX Beach 1.MP4',
    model: "tkheavenonearth.remix",
    songHigh: "",
    songLow: "",
    properties: [
      {
        trait_type: "Musician",
        value: "TK",
      },
      {
        trait_type: "3D Artist",
        value: "IMMUSE Studio",
      },
      {
        trait_type: "Genre",
        value: "Pop",
      },
      {
        trait_type: "Duration",
        value: "3:19",
      },
      {
        trait_type: "IMMUSE UI Type",
        value: "4x Mixers",
      },
    ],
  },
  {
    collectionId: 1,
    slug: "BehrouzDiscoShark",
    isPresale: false,
    price: "0.1",
    contractAddress: "0xb20f29A8937507F2Dd8FEa6c385152FF49383b8c",
    dropDate: "Available Now",
    ugc: '/ugc/disco_shark.mp4',
    chainId: 1,
    collectionName: "",
    leftText: aboutText,
    artistName: "BEHROUZ",
    subHeader: "Behrouz x DiscoShark",
    title: "Behrouz x DiscoShark",
    description: `
    Behrouz x DiscoShark - AR is a collab between Behrouz, Kevin McHugh (creator of Disco Shark), and IMMUSE that pays homage to Behrouz’s famous Miami nightclub, Do Not Sit, and celebrates all the great friends, family, DJ’s and musicians that are supporting Behrouz and his family in his time of need.
    <br/>
    <br/>
    <b>ABOUT THE SONG</b><br/>
    Behrouz x DiscoShark features an unreleased track co-produced by Behrouz and El Mundo in 2021 when Behrouz was on tour playing shows in Amsterdam and around the Netherlands. Tribal yet Gregorian, intense in all the right ways, moody, dark…  music you’d rave to in an underwater cavern club.
    <br/>
    <br/>
    <b>ABOUT THE SCENE</b><br/>
    Underground club moved underwater, the Behrouz x Discoshark scene immerses you in a version of the Don't Sit On The Furniture club as if it were a natural music cathedral sunken beneath the ocean. A Gregorian chant opens the track underneath the famous Do Not Sit ceiling lights, surrounded by pillars of kelp, like an undersea Sagrada Familia. Venus oversees the action from her oyster shell, and DiscoShark patrols the dance floor.
    <br/>
    <br/>
    `,
    external_url: "https://immuse.xyz",
    image: "/nfts/behrouzSharkLightFitting.png",
    animation_url: "/nfts/Behrouz.mp4",
    artist_image: "/nfts/behrouzSharkLightFitting.png",
    model:"",
    songHigh:"",
    songLow:"",
    properties: [
      {
        trait_type: "Musician",
        value: "Behrouz",
      },
      {
        trait_type: "3D Artis",
        value: "IMMUSE Studio",
      },
      {
        trait_type: "Genre",
        value: "EDM",
      },
      {
        trait_type: "Duration",
        value: "3:32",
      },
      {
        trait_type: "Immuse UI Type",
        value: "4x Mixers",
      },
    ],
  },
  {
    collectionId: 3,
    slug: "WaxOwlsLieForMe",
    isPresale: false,
    price: "0.1",
    contractAddress: "0xC636F9352A99c90Ee1184D7fB6E3861c5B25c649",
    leftText: aboutText,
    dropDate: "Available Now",
    chainId: 1,
    collectionName: "IMMUSE",
    artistName: "Wax Owls",
    subHeader: "Wax Owls x IMMUSE",
    title: "Lie For Me",
    ugc: '/ugc/Wax Remix Beach 4.MP4',
    description:
        `Mysterious, alluring, cinematic and tear inducing. You are dropped into a mirror world where we see two sides of ourselves with both clean and distorted views of our own judgment, doubts and desires. 
      <br/>
      <br/>
      <b>ABOUT THE SONG</b><br/>
      Independently released in August 2022, Lie for Me is a song about vulnerability. Being comfortable in one’s own skin can be difficult in the face of others. Wax Owls remind us of the comfort one can attain when being vulnerable with those we hold close - especially ourselves.
      <br/>
      <br/>
      <b>ABOUT THE SCENE</b><br/>
      Two figures stare at each other through an otherworldly mirror, reflecting different sides of self, one colorful on fire, the other subdued in data, evoking the spirit of emotional and rational selves. Wind blown leaves float on one side of the mirror, while structured primitives float on the other, illuminating our constant duality. Remixable elements include Dress One, Dress Two, the Mirror Frame, and the Particles.
      <br/>
      <br/>
        `,
    external_url: "https://immuse.xyz",
    image: "/nfts/WaxOwls_LieForMe.jpg",
    animation_url: "/nfts/WaxOwls_LieForMe_AnimatedThumbnail.mp4",
    artist_image: "/nfts/WAX-OWLS.png",
    model: "TBD",
    songHigh: "",
    songLow: "",
    properties: [
      {
        trait_type: "Musician",
        value: "Wax Owls",
      },
      {
        trait_type: "3D Artist",
        value: "IMMUSE Studio",
      },
      {
        trait_type: "Genre",
        value: "Indie",
      },
      {
        trait_type: "Duration",
        value: "3:39",
      },
      {
        trait_type: "IMMUSE UI Type",
        value: "4x Mixers",
      },
    ],
  },
  {
    collectionId: 4,
    slug: "TiaPThisMyCity",
    isPresale: false,
    collectionName: "IMMUSE",
    price: "0.1",
    contractAddress: "0xF8BE52DDe95cCB56394EaDFecef06fFccaf715e0",
    dropDate: "Available Now",
    leftText: aboutText,
    chainId: 1,
    artistName: "Tia P.",
    subHeader: "Tia P. x IMMUSE",
    title: "This My City",
    description:
        `Tia P. shines a light on her hometown, Inglewood, CA, a cultural hotbed of music, entertainment, and athletics, as it emerges from the shadows of Los Angeles. With local shout outs, slick lyrics and hard banging horns, this one’s sure to make every other city wish they had their own Tia P.
      <br/>
      <br/>
      <b>ABOUT THE SONG</b><br/>
      As the official anthem of Inglewood, This My City pays homage to Inglewood, CA, hometown of artist, producer, and songwriter Tia P. With a an all-star roster of iconic locations name dropped throughout the song, it truly feels like the listener is cruising through town with Tia P. at the wheel. 
      <br/>
      <br/>
      <b>ABOUT THE SCENE</b><br/>
      Music, art and culture can transform a city - which is exactly what’s happening in Inglewood 2022. As the ground breaks open, an explosive stack of speakers, amps, turntables and instruments emerges on hydraulics, thumping and hopping to the beat, as a radio tower transmits a powerful signal across the city. Remixable elements include the Speakers, the Signal, the Sound Waves, and the Lighting.
      <br/>
      <br/>
      `,
    external_url: "https://immuse.xyz",
    image: "/nfts/TiaP_ThisMyCity.jpg",
    animation_url: "/nfts/TiaP._ThisMyCity_AnimatedThumbnail.mp4",
    artist_image: "/nfts/TIA-P.jpg",
    model: "TBD",
    ugc: '/ugc/Tia P horns.MP4',
    songHigh: "",
    songLow: "",
    properties: [
      {
        trait_type: "Musician",
        value: "Tia P.",
      },
      {
        trait_type: "3D Artist",
        value: "IMMUSE Studio",
      },
      {
        trait_type: "Genre",
        value: "Hip Hop",
      },
      {
        trait_type: "Duration",
        value: "3:49",
      },
      {
        trait_type: "IMMUSE UI Type",
        value: "4x Mixers",
      },
    ],
  },
  {
    collectionId: 2,
    slug: "NiftySaxAltair",
    isPresale: false,
    price: "Free",
    contractAddress: "0x128d3b4fDce9803Aa422E417885ED527116A31dB",
    dropDate: "Available Now",
    chainId: 1,
    leftText: aboutText,
    collectionName: "IMMUSE",
    artistName: "NiftySax",
    subHeader: "NiftySax x IMMUSE",
    ugc: '/ugc/Nifty.mp4',
    title: "Altair",
    description:
        `“Altair - AR” is soothing yet mysterious. Calming yet haunting. Just like outer space - which is exactly where IMMUSE set the scene for this Nifty Sax Visual Single. Transform your world into a stardust filled music galaxy.
      <br/>
      <br/>
      <b>ABOUT THE SONG</b><br/>
      “Altair” is 1 of 55 unique audio-reactive tokens in the Spheres Collection, dropped by Nifty Sax - an OG innovator in Web3 Music. The project minted and sold out in Sep 2021, paving the way for Web3 music.
      <br/>
      <br/>
      <b>ABOUT THE SCENE</b><br/>
      Set in outer space, two celestial bodies orbit each other, distorting the gravitational field, emitting energy waves of light and sound. Saxtranauts tumble and drift about, as if ejected from the sax itself, a nod to Kubrick’s, 2001: A Space Odyssey. The original Spheres artwork is reimagined as an audio reactive Milky Way galaxy. Remixable elements include Star One, Star Two, the Saxtronauts, and the Space Dust.
      <br/>
      <br/>
      `,
    external_url: "https://drops.immuse.xyz/nft-item-details/1",
    image: "/nfts/NiftySax_Altair.jpg",
    animation_url: "/nfts/NiftySax_Altair_AnimatedThumbnail.mp4",
    artist_image: "/nfts/NIFTY-SAX.jpg",
    model:
        "https://immuse-bucket.s3.amazonaws.com/unity-bundles/niftysax.remix",
    songHigh:
        "https://twothirtysix-com.mo.cloudinary.net/ipfs/bafybeick4udlcj7m44nhigrqpqzudghejmtregljfta44fqrvyczb5ddyu/altair.mp3",
    songLow:
        "https://twothirtysix-com.mo.cloudinary.net/ipfs/bafybeick4udlcj7m44nhigrqpqzudghejmtregljfta44fqrvyczb5ddyu/altair.mp3",
    properties: [
      {
        trait_type: "Musician",
        value: "Nifty Sax",
      },
      {
        trait_type: "3D Artist",
        value: "IMMUSE Studio",
      },
      {
        trait_type: "Genre",
        value: "Jazz",
      },
      {
        trait_type: "Duration",
        value: "1:42",
      },
      {
        trait_type: "IMMUSE UI Type",
        value: "4x Mixers",
      },
    ],
  },
  {
    collectionId: 0,
    slug: "DrStrangerthings",
    isPresale: false,
    price: "0.01",
    contractAddress: "0x7da6d5b5d69967aaaa36ade9cc5a22afbc048e97",
    soldOut: true,
    dropDate: "TBA",
    openseaUrl: "dr-strangerthings",
    chainId: 1,
    ugc: '/ugc/DRremixvid.mov',
    collectionName: "IMMUSE",
    leftText: aboutText,
    artistName: "IMMUSE",
    title: "DR.STRANGERTHINGS",
    subHeader: "IMMUSE",
    description:
      `DR.STRANGERTHINGS is the genesis IMMUSE drop combining an original synthwave track with a fractured portal opening into an 80’s retro universe. Composed by HVY, IMMUSE’s Creative Director, DR.STRANGERTHINGS is a visual and sonic homage to the mind-bending and time-jumping techniques of 80’s comic books and sci-fi films.
      <br/>
      <br/>
      `,
    external_url: "https://immuse.xyz",
    image: "/nfts/DrStrangerThings.jpg",
    animation_url: "/nfts/DrStrangerThings_AnimatedThumbnail.mp4",
    artist_image: "/nfts/DrStrangerThings.jpg",
    model: "drstrangerthings.remix",
    songHigh:
      "https://twothirtysix-com.mo.cloudinary.net/ipfs/bafybeiagochyk4si6hkhl7firb5zpknzeadhumh7uqgfice2tsj2ryri7a/Synth_Dreamer.mp3",
    songLow:
      "https://twothirtysix-com.mo.cloudinary.net/ipfs/bafybeiagochyk4si6hkhl7firb5zpknzeadhumh7uqgfice2tsj2ryri7a/Synth_Dreamer.mp3",
    properties: [
      {
        trait_type: "Musician",
        value: "HVY",
      },
      {
        trait_type: "3D Artist",
        value: "IMMUSE Studio",
      },
      {
        trait_type: "Genre",
        value: "Synthwave",
      },
      {
        trait_type: "Duration",
        value: "2:05",
      },
      {
        trait_type: "IMMUSE UI Type",
        value: "4x Sliders",
      },
    ],
  },
  // {
  //   collectionId: 6,
  //   slug: "TestContractDeploy",
  //   isPresale: false,
  //   price: "0.0001",
  //   contractAddress: "0xeeB2994486a8e5CF989F61d3D6Bc185AeB5A0d7e",
  //   soldOut: false,
  //   dropDate: "Available Now",
  //   openseaUrl: "",
  //   chainId: 1,
  //   collectionName: "IMMUSE",
  //   artistName: "Behrouz x DiscoShark",
  //   title: "Coming From - AR",
  //   subHeader: "IMMUSE",
  //   description:
  //       "DR.STRANGERTHINGS is the genesis IMMUSE drop combining an original synthwave track with a fractured portal opening into an 80’s retro universe. Composed by HVY, IMMUSE’s Creative Director, DR.STRANGERTHINGS is a visual and sonic homage to the mind-bending and time-jumping techniques of 80’s comic books and sci-fi films.",
  //   external_url: "https://immuse.xyz",
  //   image: "/nfts/ImmuseTestDeploy.jpg",
  //   animation_url: "/nfts/DrStrangerThings_AnimatedThumbnail.mp4",
  //   artist_image: "/nfts/ImmuseTestDeploy.jpg",
  //   model: "drstrangerthings.remix",
  //   songHigh:
  //       "https://twothirtysix-com.mo.cloudinary.net/ipfs/bafybeiagochyk4si6hkhl7firb5zpknzeadhumh7uqgfice2tsj2ryri7a/Synth_Dreamer.mp3",
  //   songLow:
  //       "https://twothirtysix-com.mo.cloudinary.net/ipfs/bafybeiagochyk4si6hkhl7firb5zpknzeadhumh7uqgfice2tsj2ryri7a/Synth_Dreamer.mp3",
  //   properties: [
  //     {
  //       trait_type: "Musician",
  //       value: "HVY",
  //     },
  //     {
  //       trait_type: "3D Artist",
  //       value: "IMMUSE Studio",
  //     },
  //     {
  //       trait_type: "Genre",
  //       value: "Synthwave",
  //     },
  //     {
  //       trait_type: "Duration",
  //       value: "2:05",
  //     },
  //     {
  //       trait_type: "IMMUSE UI Type",
  //       value: "4x Sliders",
  //     },
  //   ],
  // },
];
