import React from 'react';
import PageTitle from '../components/pagetitle';
import { Link } from 'react-router-dom';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
import Partner from '../features/partner';
import dataPartner from '../assets/fake-data/data-partner';

const divStyle = {
    paddingLeft: '30px',
    paddingRight: '30px',
};

function TeamDetail(props) {
    return (
        <div>
            <PageTitle title='Meet our TEAM' />

            <div className="content mobie-30" data-aos="fade-up" data-aos-duration="800" style={divStyle}>
                <div className="h7 txt-tranf-cap m-b16">
                    Global Artists & Technologists
                </div>
                <p>Immuse is a worldwide team of innovators, creators, artists, technologists and entrepreneurs dedicated to building a new framework for music, art and culture in an open metaverse. 30+ combined years of award winning augmented reality art. 20+ combined years of tech startup success. Music rights, A&R, and 3D technical art wizardry. We are on a mission to help manifest a creative, joyful and just world. Join us!</p>
            </div>

            <Team data={dataTeam} />

            <Partner data={dataPartner} />
        </div>
    );
}

export default TeamDetail;