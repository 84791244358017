import React from "react";

const NftMetadata = ({ metadata }) => {
  return metadata.map((item) => {
    return (
      <div className='box corner-box' key={`metadata-${item.value}`}>
        <p>{item.trait_type}</p>
        <h6 className='h7'>{item.value}</h6>
      </div>
    );
  });
};

export default NftMetadata;
