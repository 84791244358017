import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Link } from "react-router-dom";
import ProjectItem from "../project/project-item";

Upcomingdrops.propTypes = {
  data: PropTypes.array,
};

function Upcomingdrops(props) {
  const { data } = props;

  return (
    <section className="tf-section tf-upcomingdrops">
      <div className="container">
        <h4 className="title">UPCOMING DROPS</h4>
        <div className="row">
          {data.map((item) => (
            <ProjectItem item={item} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Upcomingdrops;
