import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './styles.scss';


FlatButton.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
};

function FlatButton(props) {
    const {title , path , onClick} = props;
    return (
        <Link style={{fontWeight: 'bold', textTransform: 'uppercase', color: '#fff', fontFamily: 'Chakra Petch'}} to={path} onClick={onClick}>                                    
            <div>{title}</div>
        </Link>
    );
}

export default FlatButton;