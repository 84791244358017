import { dataMarketplace } from "../utils/Schemas";

const useNftDetails = () => {
  const findNFT = (slug) => {
    const data = dataMarketplace.find(
      (nft) => nft.slug && nft.slug.toLowerCase() === String(slug).toLowerCase()
    );
    return data;
  };
  return { findNFT };
};

export default useNftDetails;
