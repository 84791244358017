import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { CURRENT_NETWORK } from "../../utils/Schemas";
import { chain } from "wagmi";

Popup.propTypes = {
  result: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  contract: PropTypes.string,
  hash: PropTypes.string,
};

export default function Popup(props) {
  const { result, message, hash, loading, error, address } = props;
  const pending = !loading && !result && !error;
  return (
    <div className="container-popup-modal">
      <div id="popup">
        <div id="window">
          {pending && (
            <div>
              <p>Transaction initiated, check your wallet for instructions.</p>
            </div>
          )}
          {loading && (
            <div>
              <p>Processing...</p>
              <a
                target="_blank"
                href={`https://${
                  CURRENT_NETWORK === chain.goerli ? "goerli." : ""
                }etherscan.io/tx/${hash}`}
                rel="noreferrer"
              >
                <u>View on Etherscan</u>
              </a>
            </div>
          )}
          {result && (
            <div>
              <p>Successful Transaction</p>
              <a
                target="_blank"
                href={`https://${
                  CURRENT_NETWORK !== chain.mainnet ? "testnets." : ""
                }opensea.io/${address}`}
                rel="noreferrer"
              >
                <u>View on Opensea</u>
              </a>
            </div>
          )}
          {error && (
            <div>
              <p>Failed Transaction</p>
              <p>{message.replace(/ *\([^)]*\) */g, "")}</p>
            </div>
          )}
          {!loading && (
            <button id="close-icon" onClick={props.handleClose}>
              OK
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
