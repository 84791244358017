import axios from "axios";
import { IMMUSE_BACKEND_API } from "./constants";

export const IMMUSE_API = axios.create({
  baseURL: IMMUSE_BACKEND_API,
  timeout: 100000,
  headers: {
    Accept: "application/json",
  },
});
