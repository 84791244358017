export const generateAvatar = (address) => {
    let char = address?.charAt?.(3) || 'a';
    if ('1abc'.indexOf(char) > -1) {
      return './assets/avatars/1.svg';
    } else if ('2def'.indexOf(char) > -1) {
      return './assets/avatars/2.svg';
    } else if ('3ghi'.indexOf(char) > -1) {
      return './assets/avatars/3.svg';
    } else if ('4jkl'.indexOf(char) > -1) {
      return './assets/avatars/4.svg';
    } else if ('5mno'.indexOf(char) > -1) {
      return './assets/avatars/5.svg';
    } else if ('6pqr'.indexOf(char) > -1) {
      return './assets/avatars/6.svg';
    } else if ('7stu'.indexOf(char) > -1) {
      return './assets/avatars/7.svg';
    } else if ('8vwx'.indexOf(char) > -1) {
      return './assets/avatars/8.svg';
    } else if ('9yz'.indexOf(char) > -1) {
      return './assets/avatars/9.svg';
    } else {
      return './assets/avatars/10.svg';
    }
  };