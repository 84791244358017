import { ethers } from 'ethers';
import keccak256 from 'keccak256';
import { MerkleTree } from 'merkletreejs';
import list from './list';

export default function merkleProof(account) {
  try {
    ethers.utils.getAddress(account);
    const leafNodes = list.map((addr) =>
      keccak256(ethers.utils.getAddress(addr)),
    );
    const allowList = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    const rootHash = allowList.getHexRoot();
    // console.log("Root Hash  " + rootHash)

    const hexProof = allowList.getHexProof(keccak256(account));
    return hexProof
    
  } catch (error) {
    console.log(error);
    
  }
}