const dataAbout = [
    {
        id: 1,
        title: 'Visual Single',
        desc: 'A song you can see. Musicians and 3D artists collaborate to create a metaverse music video that is playable in AR through the Immuse App.',
    },
    {
        id: 2,
        title: 'Remix',
        desc: 'Visual Singles are designed with variable traits that you combine and select. Create AR performances in different physical locations. Save your Remixes to place, film and share with your Community.',
    },
    {
        id: 3,
        title: 'AR Stages',
        desc: 'COMING SOON. Mint your Remixes as shareable NFTs to place in persistent AR for the Community to discover and experience for themselves, earning RMIX tokens for stage visits.',
    },
]

export default dataAbout;