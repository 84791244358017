
import img1 from '../images/logo/animoca.png'
import img2 from '../images/logo/bigbrains.png'
import img3 from '../images/logo/gsr.png'
import img4 from '../images/logo/cogitent.png'
import img5 from '../images/logo/brinc.png'
import img6 from '../images/logo/niantic.png'
import img7 from '../images/logo/polygon.png'

const dataPartner = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img3,
    },
    {
        img: img4,
    },
    {
        img: img5,
    },
    {
        img: img6,
    },
    {
        img: img7,
    },
]
export default dataPartner;