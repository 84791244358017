import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { generateAvatar } from "./utils/generateAvatar";
import App from "./App";
import "./App.scss";
import ScrollToTop from "./ScrollToTop";

import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { Buffer } from "buffer/";
import { CURRENT_NETWORK } from "./utils/Schemas";

window.Buffer = window.Buffer || Buffer;
const { chains, provider } = configureChains(
  CURRENT_NETWORK === chain.mainnet
    ? [chain.mainnet, chain.polygon]
    : [chain.goerli, chain.polygonMumbai],
  [
    alchemyProvider({ apiKey: "x5KVTi6vWIzPY-jhIBtAbv-sqnUHSAAG" }),
    publicProvider(),
  ]
);
const { connectors } = getDefaultWallets({
  appName: "IMMUSE",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const CustomAvatar = ({ address }) => {
  const color = generateAvatar(address);
  return (
    <img
      src={require(`${color}`)}
      alt=""
      width={50}
      height={50}
      style={{ borderRadius: 999 }}
    />
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider avatar={CustomAvatar} chains={chains}>
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </RainbowKitProvider>
      </WagmiConfig>
    
  </React.Fragment>
);
