// Vendors
import React from "react";

// Components
import PageTitle from "../components/pagetitle";
import Marketplace from "../features/project/marketplace";

// utils
import { dataMarketplace } from "../utils/Schemas";

function Market(props) {

  const titleStyle = {
    padding: '50px 0 59px'
  };

  return (
    <div className='page-nft'>
      <PageTitle style={titleStyle} />

      <Marketplace data={dataMarketplace} />
    </div>
  );
}

export default Market;
