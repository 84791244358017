import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './styles.scss';

TeamItem.propTypes = {
  item: PropTypes.object,  
};

const divStyle = {
    lineHeight: '60%',
};

function TeamItem(props) {
    const {item} = props;
    return (
        <div className="team-box">
            <div className="image">
                <img src={item.img} alt="Crybox" />
            </div>
            <div className="content">
                <div className="h8"><h8>{item.name}</h8></div>
                <p>{item.position}</p>
                <ul className="social">
                    {
                        item.listsocial.map(icon => {
                            if(icon.link != '')
                            return <li key={icon.id}>
                                        <a href={icon.link}><i className={icon.icon}></i></a>
                                    </li>
                            else return <p style={divStyle}>&nbsp;</p>
                        })
                    }
                </ul>
            </div>
        </div>
    );
}

export default TeamItem;