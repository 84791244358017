import React from "react";

import Slider from "../components/slider";
import dataSlider from "../assets/fake-data/data-slider";
import About from "../features/about";
import dataAbout from "../assets/fake-data/data-about";
import RoadMap from "../features/roadmap";
import dataRoadmap from "../assets/fake-data/data-roadmap";
import Work from "../features/work";
import dataWork from "../assets/fake-data/data-work";
import Partner from "../features/partner";
import dataPartner from "../assets/fake-data/data-partner";
import Upcomingdrops from "../features/upcomingdrops";

import { dataMarketplace } from "../utils/Schemas";

function HomeOne(props) {
  const upcoming = dataMarketplace?.filter((e) => e.isPresale);
  return (
    <div className="home-1">
      <Slider data={dataSlider} />

      <Upcomingdrops data={upcoming} />

      <About data={dataAbout} />

      <RoadMap data={dataRoadmap} />

      <Work data={dataWork} />

      <Partner data={dataPartner} />
    </div>
  );
}

export default HomeOne;
